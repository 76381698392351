import styled from 'styled-components';

export const Container = styled.input`
  width: 100%;
  border: none;
  height: 28px !important;
  border-bottom: 1px solid #dddddd !important;
  margin-bottom: 22px;
  margin-top: 22px;
  font-family: roboto;
  font-weight: normal;
  font-size: 16px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }
`;
