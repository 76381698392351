import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  height: auto;
  > div {
    min-height: 100vh;
    background-color: #f0f0f0;
    padding-bottom: 50px;
  }
`;
export const MainInfoUser = styled.div`
  height: auto;
  max-width: 320px;
  margin: 0 auto;
  margin-top: 36px;
  display: flex;
  align-items: center;
`;
export const ImgInfoProfile = styled.div`
  width: 62px;
  img {
    height: auto;
    width: 62px !important;
  }
`;

export const DataUserProfile = styled.div`
  width: 255px;
  float: right;
  font-family: roboto;
  padding-left: 10px !important;
  height: 62px;
  h4 {
    text-align: left;
    font-size: 17px;
    font-weight: 900 !important;
    color: #29b473;
  }
`;
export const DataNumbers = styled.div`
  width: 240px;
  margin-top: 3px;
  font-family: roboto;
  display: flex;
  p {
    font-size: 14px;
    font-weight: normal;
    color: #00adee;
    width: 200px;
    float: left;
  }
  span {
    font-size: 14px;
    font-weight: normal;
    color: #a0a0a0;
    float: right;
    width: 40px;
    text-align: right;
  }
`;

export const InfoUpdate = styled.div`
  width: 240px;
  margin-top: 3px;
  font-family: roboto;
  display: flex;
  p {
    font-size: 12px;
    font-weight: normal;
    color: #00adee;
    width: 100px;
    float: left;
  }
  span {
    font-size: 12px;
    font-weight: normal;
    color: #a0a0a0;
    float: right;
    width: 140px;
    text-align: right;
  }
`;
export const HighlightsTitle = styled.div`
  width: 320px;
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 21px;
  // display: flex;
  p {
    text-align: center;
    font-family: roboto;
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    width: 100px;
    float: left;
  }
  img {
    width: 80%;
  }
`;
export const HighlightsImages = styled.div`
  width: 320px;
  margin: 0 auto;
  margin-top: 30px !important;
  img {
    width: 100%;
    display: inline;
  }
`;
export const FunctionalityTitle = styled.div`
  width: 320px;
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 21px;
  p {
    text-align: center;
    font-family: roboto;
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    width: 100px;
    float: left;
  }
`;
export const FunctionalityButons = styled.div`
  width: 320px;
  margin: 0 auto;
  margin-top: 19px;
  display: flex;
`;
export const SingleButton = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 3px;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  background: linear-gradient(
    150deg,
    rgba(41, 180, 115, 1) 0%,
    rgba(0, 165, 230, 1) 50%,
    rgba(0, 165, 230, 1) 92%
  );
  img {
    width: 20px;
    height: auto;
  }
  p {
    color: #ffffff;
    font-family: roboto;
    font-size: 13px;
    margin-top: 30px;
  }
`;
