import styled from 'styled-components';

export const MyHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff !important;
  width: 100%;
  height: 70px !important;
  text-align: center;
  font-size: 18px;
  box-shadow: 0 0 10pt #1b1c201a;
`;
export const ContentHeader = styled.div`
  height: 70px !important;
  width: 350px !important;
  margin: 0 auto;
  display: flex;
`;
export const MenuHeader = styled.div`
  width: 100px;
  text-align: left;
  padding-top: 25px;
  img {
    width: 20px;
  }
`;
export const BrandHeader = styled.div`
  width: 150px;
  text-align: center;
  padding-top: 10px;
  img {
    width: 80%;
  }
`;
export const AlertHeader = styled.div`
  width: 100px;
  text-align: right;
  padding-top: 20px;
  img {
    width: 30px;
  }
`;
