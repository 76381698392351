import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  height: auto;
  max-width: 400px;
  margin: 0 auto;
  background-image: url('/assets/img/bg-register-success.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  > div {
    min-height: 100vh;
    background-image: linear-gradient(#00000098, #00000019);
  }
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 80%;
    height: auto;
    margin: 0 auto;
    margin-top: 80px !important;
  }
`;

export const StartInfo = styled.div`
  padding-top: 40px;
  font-size: 17px;
  font-family: roboto;
  letter-spacing: 1.2px;
  width: 290px;
  text-align: center;
  margin: 0 auto;
  h4 {
    color: #ffffff;
    margin-bottom: 25px;
    font-weight: bold;
  }
  p {
    color: #ffffff;
    font-size: 13px !important;
    font-weight: normal !important;
    margin-top: 10px;
    line-height: 20px;
    margin-bottom: 10px;
  }
`;
export const BoxButtonContinue = styled.div`
  padding-top: 270px;
  font-size: 17px;
  font-family: roboto;
  letter-spacing: 1.2px;
  width: 290px;
  text-align: center;
  margin: 0 auto;
`;
