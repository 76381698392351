import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  height: auto;
  width: 100vw;
  > div {
    min-height: 100vh;
    background-color: #f0f0f0;
    padding-bottom: 50px;
    // overflow: hidden;
  }
`;

export const FindPevTitle = styled.div`
  width: 320px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  p {
    text-align: center;
    font-family: roboto;
    font-size: 16px;
    color: #000000;
    font-weight: normal;
    width: 100%;
    float: left;
  }
`;

export const FindPevMap = styled.div`
  overflow-x: scroll !important;
  overflow-y: hidden;
  position: relative !important;
  margin-top: 55px;
  height: 530px;
  width: 100% !important;
  background-color: #cccccc;
  position: relative;
`;
export const ContainerCarousel = styled.div`
  position: absolute !important;
  z-index: 9999 !important;
  margin-top: 220px;
`;
export const BaseCarrossel = styled.div`
  height: 250px;
  text-align: center;
  z-index: 9999 !important;
  margin-top: 50px !important;
`;
export const Scrolling = styled.div`
  height: 250px;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: auto;
`;
export const FindPevCards = styled.div`
  box-shadow: 1px 1px 10px #999;
  margin: 15px;
  padding: 10px;
  border-radius: 10px;
  text-align: left !important;
  width: 250px !important;
  height: 200px;
  cursor: pointer;
  position: absolute
  display: inline-block;
  vertical-align: bottom;
  background-color: #ffffff !important;
  font-family: roboto;
  leter-spacing: 1px;
  h1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #020202;
    text-align: left;
  }
  h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #020202;
    text-align: left;
    padding-bottom: 70px;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #020202;
    text-align: left;
  }
  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2cab66 !important;
    text-align: left;
    margin-top: 5px;
  }
`;
export const PositionButton = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: 30px;
`;
