import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
  font-weight: normal;
  color: #ffffff;
  background-color: #00adee;
  border-radius: 30px;
  width: 100%;
  height: 50px;
  transition: opacity 0.3s;
  font: normal normal bold 15px Roboto;
  font-size: 14px !important;
  letter-spacing: 1px;
  box-shadow: none;
  border: none;
  &:hover {
    opacity: 0.7;
  }
`;
