import { FC, useRef, useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Creators as AuthActions } from 'src/store/ducks/auth';
import InputLogin from 'src/components/InputLogin';
import brandPayColor from 'src/assets/img/brandPayColor.svg';
import ButtonForm from 'src/components/ButtonForm';
import Checkbox from 'src/components/Checkbox';
import BockInputPay from 'src/components/BockInputPay';
import { ToastContainer, toast } from 'react-toastify';
import api from 'src/services/api';
import keys from 'src/services/keys';
import { cpfMask, cpfValida, mphone } from './mask';
import ReCAPTCHA from 'react-google-recaptcha';
import visibility from 'src/assets/img/visibility.png';
import view from 'src/assets/img/view.png';

import {
  Container,
  Brand,
  BlockLogin,
  LoginMenu,
  InfoPassword,
  CheckRegister,
  ReCaptcha,
  ImageUser,
} from './styles';
// import Checkbox from 'src/styles/themes/light/checkbox';

const Register: FC = () => {
  const url_atual = window.location.href.toLowerCase();

  const history = useHistory();
  const login = () => {
    history.push('/login');
  };

  const registerSucesso = () => {
    history.push('/cadastro-realizado');
  };

  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [recaptchavalue, setRecaptchavalue] = useState<string>('');
  const [show_input, setShowInput] = useState<boolean>(false);
  const [showPassConfirm, setShowPassConfirm] = useState<boolean>(true);
  const [showPass, setShowPass] = useState<boolean>(true);
  const [show_input_confirm, setShowInputConfirm] = useState<boolean>(false);
  const funcPassViewConfirm = (passview: boolean) => {
    setShowPassConfirm(!showPassConfirm);
    setShowInputConfirm(!show_input_confirm);
  };

  useEffect(() => {
    const getLogin = async () => {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));

      const erro = localStorage.getItem('erro');
      const sucesso = localStorage.getItem('sucesso');
    };
    localStorage.removeItem('erro');
    localStorage.removeItem('sucesso');

    getLogin();
  }, []);

  const funcErrorEmail = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const funcErrorPassword = (stateError: boolean) => {
    setErrorPassword(stateError);
  };

  const changeRecaptha = (value) => {
    setRecaptchavalue(value);
  };

  const signInPass = async () => {
    // history.push("/link-enviado");

    if (email == '') {
      toast.error('E-mail não pode ser vazio!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      funcErrorEmail(true);
    } else {
      if (password == '') {
        toast.error('Password não pode ser vazio!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        funcErrorPassword(true);
      } else {
        if (!IsEmail(email)) {
          toast.error('E-mail digitado não é valido!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          if (recaptchavalue == '') {
            toast.error('Validação do reCAPCHA é obrigatória!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            let res: any = {};
            res = await authRequest(email, password);
          }
        }
      }
    }
  };

  const changeEmail = (email: string) => {
    setEmail(email);
    funcErrorEmail(false);
    funcErrorPassword(false);
  };

  const dispatch = useDispatch();

  const loginSSO = () => {
    history.push('/login-sso');
  };

  const forgotMyPassword = () => {
    history.push('/esqueci-minha-senha');
  };

  const authRequest = async (login, pass) => {
    const loading = true;
    dispatch(AuthActions.authLoading(loading));
    const data = {
      email: login,
      password: pass,
    };
    try {
      const response = await api.user.post('/api/v1/login', data);

      const { modules, token } = response.data;
      if (modules.length == 1 && modules[0]?.user_profile == null) {
        const data = {
          uuid: modules[0].id,
        };
        try {
          const response = await api.user.post('/api/v1/login/module', data, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });

          const { companies } = response.data;
          const tokenNew = response.data.token;
          if (companies) {
            if (companies.length == 1) {
              const data = {
                uuid_company: companies[0].id,
              };
              try {
                const response = await api.user.post(
                  '/api/v1/companies/current',
                  data,
                  {
                    headers: {
                      Authorization: 'Bearer ' + tokenNew,
                    },
                  }
                );

                const dataNew = {
                  token: response.data.token,
                };

                window.location.href =
                  'api/v1/logar_by_micro_service/' + response.data.token;
              } catch (error: any) {
                if (
                  error.response.data.error ==
                  'Cannot log in as the account is in use on another device!'
                ) {
                  localStorage.setItem('erro', '2');
                  toast.error(
                    ' Não é possível efetuar o login pois a conta está em uso em outro dispositivo! ',
                    {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                }
              }
            } else {
              dispatch(AuthActions.authSelectCompanie(token, { modules }));
              const loading = false;
              dispatch(AuthActions.authLoading(loading));
              history.push('/escolher-empresa-carrefour');
            }
          }

          // dispatch(AuthActions.authSelectCompanie(token, { companies }));
        } catch (error) {}
      }
    } catch (error: any) {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));
      if (
        error.response.data.message ==
        'The maximum number of login attempts has been reached. We are sending you an email to reset your password.'
      ) {
        toast.error(
          'O número máximo de tentativas de login foi atingido. Estamos lhe enviando um e-mail para redefinir sua senha.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        if (
          error.response.data.message ==
          'Change your password to meet secure password requirements!'
        ) {
          toast.error(
            'Altere sua senha para atender aos requisitos de senha segura!',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          history.push(
            '/login/recriar-senha/' +
              error.response.data.data.id +
              '/' +
              error.response.data.data.token +
              '/senha-forte'
          );
        } else {
          toast.error('Login ou senha inválidos!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      funcErrorPassword(true);
      funcErrorEmail(true);
    }
  };

  const funcPassView = (passview: boolean) => {
    setShowPass(!showPass);
    setShowInput(!show_input);
  };

  const IsEmail = (email: string) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [nome, setNome] = useState<string>('');
  const [celular, setCelular] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');

  const [oito_caracteres, setOitoCaracteres] = useState<boolean>(false);
  const [um_numero, setUmNumero] = useState<boolean>(false);
  const [um_caractere_especial, setUmCaractereEspecial] =
    useState<boolean>(false);
  const [uma_letra, setUmaLetra] = useState<boolean>(false);
  const [uma_maiuscula, setUmaMaiuscula] = useState<boolean>(false);
  const [uma_minuscula, setUmaMinuscula] = useState<boolean>(false);
  const [nova_senha_confirm, setNovaSenhaConfirm] = useState<boolean>(false);
  const [acentuacao, setAcentuacao] = useState<boolean>(true);
  const [recaptcha_privateke, setRecaptcha_privateke] = useState<any>(
    keys.REACT_APP_SITE_KEY
  );
  const changePassword = (password: string) => {
    const numeros = /([0-9])/;
    const alfabeto = /([a-zA-Z])/;
    const maiuscula = /[A-Z]/;
    const minuscula = /([a-z])/;
    const chEspeciais = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
    const acentuacao = /([áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ])/;

    if (password.length < 8) {
      setOitoCaracteres(false);
    } else {
      setOitoCaracteres(true);
    }

    if (!password.match(acentuacao)) {
      setAcentuacao(false);
    } else {
      setAcentuacao(true);
    }

    if (!password.match(maiuscula)) {
      setUmaMaiuscula(false);
    } else {
      setUmaMaiuscula(true);
    }

    if (!password.match(minuscula)) {
      setUmaMinuscula(false);
    } else {
      setUmaMinuscula(true);
    }

    if (!password.match(chEspeciais)) {
      setUmCaractereEspecial(false);
    } else {
      setUmCaractereEspecial(true);
    }

    if (!password.match(numeros)) {
      setUmNumero(false);
    } else {
      setUmNumero(true);
    }

    if (!password.match(alfabeto)) {
      setUmaLetra(false);
    } else {
      setUmaLetra(true);
    }

    if (passwordConfirm != password) {
      setNovaSenhaConfirm(false);
    } else {
      if (passwordConfirm == '' && password == '') {
        setNovaSenhaConfirm(false);
      } else {
        setNovaSenhaConfirm(true);
      }
    }

    setPassword(password);
    funcErrorPassword(false);
  };

  const changePasswordConfirm = (passwordConfirm: string) => {
    if (passwordConfirm != password) {
      setNovaSenhaConfirm(false);
    } else {
      if (passwordConfirm == '' && password == '') {
        setNovaSenhaConfirm(false);
      } else {
        setNovaSenhaConfirm(true);
      }
    }

    setPasswordConfirm(password);
    funcErrorPasswordConfirm(false);
  };

  const changeNome = (nome: string) => {
    setNome(nome);
  };

  const changeCpf = (cpf: string) => {
    console.log(cpfMask(cpf));
    setCpf(cpfMask(cpf));
  };

  const changeCelular = (celular: string) => {
    setCelular(mphone(celular));
  };

  const funcErrorPasswordConfirm = (stateError: boolean) => {
    setErrorPassword(stateError);
  };

  const backLogin = () => {
    history.push('/');
  };

  const forgotRequest = async () => {
    if (!cpfValida(cpf)) {
      await setErrorPassword(true);
      toast.error(' Cpf invalidado! ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (nome == '') {
      await setErrorPassword(true);
      toast.error(' Nome não pode estar vazio! ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (email == '') {
      await setErrorPassword(true);
      toast.error(' E-mail não pode estar vazio! ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (!IsEmail(email)) {
      await setErrorPassword(true);
      toast.error(' E-mail precisa ser válido! ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (acentuacao) {
      await setErrorPassword(true);
      toast.error(' Senha não pode possuir acento! ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (!oito_caracteres) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter no mínimo 8 caracteres ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (!um_numero) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 número ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!um_caractere_especial) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 caractere especial ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!uma_letra) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 letra ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!uma_maiuscula) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 letra maiúscula ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!nova_senha_confirm) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Senhas não podem ser diferentes ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    redefinirPass();
    // history.push('/');
  };

  const redefinirPass = async () => {
    if (recaptchavalue == '') {
      toast.error('Validação do reCAPCHA é obrigatória!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (!IsEmail(email)) {
      } else {
        if (email == '') {
        } else {
          if (nome == '') {
          } else {
            if (!oito_caracteres) {
              funcErrorPassword(true);
            } else {
              if (!um_numero) {
                funcErrorPassword(true);
              } else {
                if (!um_caractere_especial) {
                  funcErrorPassword(true);
                } else {
                  if (!uma_letra) {
                    funcErrorPassword(true);
                  } else {
                    if (!uma_maiuscula) {
                      funcErrorPassword(true);
                    } else {
                      if (!nova_senha_confirm) {
                        funcErrorPassword(true);
                      } else {
                        if (acentuacao) {
                          funcErrorPassword(true);
                        } else {
                          const data = {
                            nome: nome,
                            email: email,
                            cpf: cpf,
                            celular: celular,
                            password: password,
                          };
                          try {
                            const loading = true;
                            dispatch(AuthActions.authLoading(loading));
                            const response = await api.user.post(
                              '/store',
                              data
                            );
                            funcErrorPassword(false);

                            if (response.data.success == false) {
                              toast.error(response.data.error, {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                              const loading = false;
                              dispatch(AuthActions.authLoading(loading));
                              funcErrorPassword(false);
                            } else {
                              toast.success('Cadastro realizado com sucesso', {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                              registerSucesso();
                              const loading = false;
                              dispatch(AuthActions.authLoading(loading));
                              funcErrorPassword(false);
                            }
                          } catch (error) {
                            const loading = false;
                            dispatch(AuthActions.authLoading(loading));
                            funcErrorPassword(false);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <Container>
      <div>
        <Brand>
          <img src={brandPayColor} alt="GreeningPay" />
        </Brand>
        <LoginMenu>
          <a onClick={() => login()}>ACESSE</a>
          <a>
            <span>CADASTRE-SE</span>
          </a>
        </LoginMenu>
        <BlockLogin>
          <BockInputPay error={errorEmail}>
            <InputLogin
              type="text"
              placeholder="Nome Completo"
              onChange={(e) => changeNome(e.target.value)}
            />
          </BockInputPay>

          <BockInputPay error={errorEmail}>
            <InputLogin
              type="text"
              placeholder="CPF"
              value={cpf}
              onChange={(e) => changeCpf(e.target.value)}
            />
          </BockInputPay>

          <BockInputPay error={errorEmail}>
            <InputLogin
              type="email"
              placeholder="E-mail (será usado para entrar)"
              onChange={(e) => changeEmail(e.target.value)}
            />
          </BockInputPay>

          <BockInputPay error={errorEmail}>
            <InputLogin
              type="text"
              placeholder="Celular (opcional)"
              value={celular}
              onChange={(e) => changeCelular(e.target.value)}
            />
          </BockInputPay>

          <BockInputPay error={errorEmail}>
            <InputLogin
              type={show_input ? 'text' : 'password'}
              placeholder="Senha de acesso"
              onChange={(e) => changePassword(e.target.value)}
            />
            <ImageUser onClick={() => funcPassView(showPass)}>
              <img
                src={showPass ? visibility : view}
                style={{
                  position: 'absolute',
                  width: '8%',
                  float: 'left',
                  right: '8px',
                  marginTop: '22px',
                }}
                alt="greenPay"
              />
            </ImageUser>
          </BockInputPay>

          <BockInputPay error={errorEmail}>
            <InputLogin
              type={show_input_confirm ? 'text' : 'password'}
              placeholder="Confirmar senha de acesso"
              onChange={(e) => changePasswordConfirm(e.target.value)}
            />
            <ImageUser onClick={() => funcPassViewConfirm(showPassConfirm)}>
              <img
                src={showPassConfirm ? visibility : view}
                style={{
                  position: 'absolute',
                  width: '8%',
                  float: 'left',
                  right: '8px',
                  marginTop: '22px',
                }}
                alt="greenPay"
              />
            </ImageUser>
          </BockInputPay>

          <ReCaptcha>
            <ReCAPTCHA
              sitekey={recaptcha_privateke}
              size="normal"
              badge="inline"
              onChange={(e) => changeRecaptha(e)}
              width={100}
            />
          </ReCaptcha>

          <InfoPassword>
            <h2>PARA UMA SENHA FORTE UTILIZE:</h2>

            <p>• 8 caracteres ou mais;</p>
            <p>• letras maiúsculas e minúsculas;</p>
            <p>• no mínimo um número;</p>
            <p>• caracteres especiais (! @ # $ &).</p>
          </InfoPassword>

          <CheckRegister>
            <InputLogin
              type="checkbox"
              onChange={(e) => changeEmail(e.target.value)}
            />
            <p>
              Li e concordo com os <a href="#">termos do contrato</a>
            </p>
          </CheckRegister>
          <ButtonForm type="button" onClick={() => forgotRequest()}>
            CADASTRAR
          </ButtonForm>
        </BlockLogin>
      </div>
    </Container>
  );
};

export default Register;
