import { call, put } from 'redux-saga/effects';
import api from 'src/services/api';
import { Creators as AuthActions } from 'src/store/ducks/auth';

interface AuthSetTokenParams {
  payload: {
    auth: {
      token: string;
    };
  };
  type: string;
}

const setToken = (token) => {
  const keys = Object.keys(api);

  keys.forEach((key) => {
    api[key].defaults.headers.Authorization = `Bearer ${token}`;
  });
};

export function* authSetToken({ payload }: AuthSetTokenParams): Generator {
  if (payload) {
    const { token } = payload.auth;

    if (token) {
      setToken(token);
    } else {
      yield put(AuthActions.authLogout());
    }
  }
}

interface AuthSuccessParams {
  token: string;
  type: string;
}

export function* authLogin({ token }: AuthSuccessParams): Generator {
  setToken(token);
}

export function* authLogout(): Generator {
  try {
    // yield call(api.user.delete, '/logout');
    // setToken('');
  } catch (error) {}
}
