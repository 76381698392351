import { FC } from 'react';

import AuthPages from '../pages/Auth';
import PrivatePages from '../pages/Private';
import PublicPages from '../pages/Public';

export interface SubRouteInterface extends RouteInterface {
  show_in_sub_menu?: boolean;
}

export interface RouteInterface {
  exact?: boolean;
  component: FC;
  label: string;
  path: string;
  title?: string;
  type: 'auth' | 'private' | 'public';
  breadcrumbs?: string[];
  sub_routes?: SubRouteInterface[];
}

export const authRoutes: RouteInterface[] = [
  {
    exact: true,
    component: AuthPages.Start,
    label: 'Início',
    path: '/',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.Login,
    label: 'Login',
    path: '/login',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.Register,
    label: 'Cadastrar',
    path: '/cadastrar',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.ToRecover,
    label: 'Recuperar Senha',
    path: '/recuperar-senha',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.ToRecoverSuccess,
    label: 'Recuperar Senha Sucesso',
    path: '/recuperar-senha-sucesso',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.RegisterSuccess,
    label: 'Cadastro Sucesso',
    path: '/cadastro-realizado',
    type: 'auth',
  },
];

export const privateRoutes: RouteInterface[] = [
  {
    exact: true,
    component: AuthPages.Main,
    label: 'Tela de boas vindas',
    path: '/home',
    type: 'private',
  },
  {
    exact: true,
    component: AuthPages.FindPev,
    label: 'Encontre um PEV',
    path: '/encontre-um-pev',
    type: 'private',
  },
  {
    exact: true,
    component: AuthPages.RegisterDiscard,
    label: 'Registrar Descarte',
    path: '/registrar-descarte',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.RegisterDataDiscard,
    label: 'Registrar Dados de Descarte',
    path: '/registrar-descarte-dados',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.RegisterDiscardSucess,
    label: 'Registrado Descarte com Sucesso',
    path: '/registrar-descarte-sucesso',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.ConfirmDeparture,
    label: 'Confirmar Saída',
    path: '/confirmar-saida',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.ConfirmChangePassword,
    label: 'Confirmar Alteração de Senha',
    path: '/confirmar-altraecao-senha',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.VerificationCode,
    label: 'Verifica Código',
    path: '/verificar-codigo',
    type: 'auth',
  },
  {
    exact: true,
    component: AuthPages.MyVouchers,
    label: 'Meus Vouchers',
    path: '/meus-vouchers',
    type: 'auth',
  },
];

export const publicRoutes: RouteInterface[] = [
  {
    component: PublicPages.NotFound,
    label: 'Página não encontrada',
    path: '*',
    type: 'public',
  },
];

export default [...authRoutes, ...privateRoutes, ...publicRoutes];
