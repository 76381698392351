import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  margin-top: 30px;

  font-weight: bold;
  color: #29b473;
  background-color: #ffffff;
  border-radius: 30px;
  width: 100%;
  height: 50px;
  transition: opacity 0.3s;
  font: normal normal bold 18px Roboto;
  letter-spacing: 2px;
  box-shadow: none;
  border: 2px solid #29b473 !important;
  &:hover {
    opacity: 0.7;
  }
`;
