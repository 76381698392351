import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  height: auto;
  max-width: 400px;
  margin: 0 auto;
  background-image: url('/assets/img/bg-start-pay.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  > div {
    min-height: 100vh;
    background-image: linear-gradient(#00000019, #00000098);
  }
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 80%;
    height: auto;
    margin: 0 auto;
    margin-top: 80px !important;
  }
`;

export const StartInfo = styled.div`
  padding-top: 320px;
  font-size: 27px;
  font-family: roboto;
  letter-spacing: 1.5px;
  width: 290px;
  text-align: center;
  margin: 0 auto;
  h4 {
    color: #ffffff;
    margin-bottom: 5px;
  }
  h4 span {
    color: #00adee;
    text-align: center !important;
  }
  p {
    color: #ffffff;
    font-size: 16px !important;
    font-weight: normal;
    margin-top: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
`;
