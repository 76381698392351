import styled from 'styled-components';

export const MyMenu = styled.div`
  width: 350px;
  height: 100vh;
  background-color: #ffffff;
  padding: 67px 20px 100px 27px;
  position: fixed;
  top: 0;
  left: 0;
  x-index: 9999;
  left: -350px;
  ul {
    list-style: none;
    padding-left: 0 !important;
    li {
      font-size: 16px;
      line-height: 22px;
      font-family: roboto;
      font-weight: bold;
      leter-spacing: 1px;
      padding: 30px 0;
      a {
        color: #000000;
      }
    }
  }
`;
export const FooterMenu = styled.div`
  width: 290px;
  height: 130px;
  position: fixed;
  bottom: 0;
  img {
    width: 200px;
    margin: 0 0 25px 40px;
  }
  p {
    text-align: center;
    color: #808080;
    font-size: 16px;
    font-family: roboto;
  }
`;
export const HeaderMenu = styled.div`
  width: 290px;
`;
export const SectionClose = styled.div`
  width: 260px;
  height: 30px;
  padding-bottom: 30px;
  img {
    width: 30px;
    height: auto;
    display: inline;
    float: left;
  }
  p {
    width: 230px;
    float: right;
    line-height: 30px;
    text-align: center;
  }
`;
export const SectionInfoUser = styled.div`
  width: 290px;
  margin-top: 30px;
  margin-bottom: 70px;
  font-family: roboto;
  img {
    width: 62px;
    margin-right: 10px;
  }
  p {
    width: 210px;
    float: right;
    padding: 0;
    margin: 0;
    color: #29b473;
    font-size: 17px;
    margin-top: 10px;
  }
  p span {
    width: 210px;
    float: right;
    padding: 0;
    margin-top: -40px;
    color: #202020;
    font-size: 14px !important;
  }
`;
