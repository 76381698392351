import { FC } from 'react';

import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import PrivateLayout from 'src/layouts/Private';
import { RootState } from 'src/store/ducks/index';
import Home from 'src/pages/Auth/Main';

import ExtendedRouteProps from '../interfaces/ExtendedRouteProps';

const Private: FC<ExtendedRouteProps> = ({
  component: Component,
  title,
  breadcrumbs,
  ...rest
}) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        isAuthenticated ? (
          <>
            <Home />
            {/* <Helmet>
              <title>{title}</title>
            </Helmet>
            <PrivateLayout breadcrumbs={breadcrumbs}>
              <Component {...props} />
            </PrivateLayout> */}
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default Private;
