import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  height: auto;
  > div {
    height: 100vh;
    background-color: #fafafa;
    padding-bottom: 50px;
  }
`;

export const InputLabel = styled.label`
  color: #00adee !important;
  text-align: left !important;
  font-family: roboto;
  font-weight: 900 !important;
  font-size: 14px;
  line-height: 30px;
  width: 100% !important;
  display: block !important;
  margin-bottom: -15px !important;
  vertical-align: middle !important;
`;

export const ImageMain = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
  > img {
    width: 133px !important;
  }
`;

export const PopUp = styled.section`
  min-height:70%
  background-color: #ffffff;
  padding: 21px 20px;
  margin-top: 133px;
  max-width: 320px;
  margin: 0 auto;
  border-radius: 20px 20px 0 0;
  -webkit-box-shadow: 0px -3px 8px 0px #00000012;
  box-shadow: 0px -3px 8px 0px #00000012;
  font-family: roboto;
  > h2 {
    color: #00ADEE;
    text-align: center;
    font-size: 24px;    
    font-weight: 900 !important;
    margin-top: 13px;
    margin-bottom: 23px;
  }
  > h4{
    color: #00A96D;
    text-align: left;
    font-size: 16px;   
    line-height:22px; 
    font-weight: 900 !important;
    // margin-top: 13px;
    // margin-bottom: 23px;
  }
  > p {
    color: #000000;
    text-align: left;
    font-size: 15px;   
    line-height:22px; 
    font-weight: normal;
    margin-bottom: 48px;
    margin-top: 20px;
  }
  > h5 {
    color: #000000;
    text-align: left;
    font-size: 15px;   
    line-height:22px; 
    margin-top: 22px !important;
    margin-bottom: 30px !important;
  }
`;

export const Arrow = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  > img {
    height: 10px !important;
  }
`;

export const LoginMenu = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 80%;
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
  margin-top: 80px;
  font-size: 18px;
  font-family: roboto;
  font-weight: 900 !important;
  cursor: pointer;
  a {
    color: #000000;
    text-decoration: none;
    text-align: center;
    padding: 0 25px;
  }
  a span {
    border-bottom: 4px solid #00adee;
    padding-bottom: 10px !important;
    padding: 0 5px;
  }
`;

export const BlockLogin = styled.div`
    width: 80%;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px 25px 30px 25px;
    margin-top: 50px;
    p {
      margin-top: 34px !important;
      font-size: 16px;
      font-family: roboto;
      font-weight: normal;
    }
    a {
      color: #B0B0B0;    
      text-decoration: none;  
    }
  }
`;
export const InfoPassword = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: left;
    font-size: 14px;
    font-family: roboto;
    font-weight: normal;
    color: #808080;
    h2 {
      font-size: 14px;
      font-family: roboto;
      font-weight: bold;
      text-align: center !important;
      color: #A0A0A0 !important;
      letter-spacing: 1px;
      margin-bottom: 15px;
      margin-top: 5px;
    }
    p {
      color: #A0A0A0;    
      font-size: 15px;
      margin: 0px !important;
      padding: 0 !important;
      line-height:19px !important;
    }
  }
`;
export const CheckRegister = styled.div`
    // background-color: #ff0000;
    display: flex;
    margin-top: 25px;
    justify-content: start;
    align-items: left;
    input {
      width: 20px !important;
      height: 20px !important;
      float: left !important;
      margin-top: 0 !important;
      padding-top: 0 !important;      
    }
    input:checked{
      background-color: #959595 !important;
      // width: 25px;
      // height: 25px;
    }
    p {
      color: #808080 !important;
      line-height:15px !important;
      margin-top: 0 !important;
      padding-top: 0 !important;
      text-align: left !important;
      padding-left: 10px !important;
      a{
        color: #00ADEE !important;
      }
    }
  }
`;
