import styled from 'styled-components';
interface IContainerProps {
  error: boolean;
}
export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5px;
  border-radius: 0;
  // background: #ffffff 0% 0% no-repeat padding-box;
  position: relative;
`;
