import { FC, useRef, useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Creators as AuthActions } from 'src/store/ducks/auth';
import InputLogin from 'src/components/InputLogin';
import ArrowDown from 'src/assets/img/angle-down-solid.png';
import Trash from 'src/assets/img/trash.png';
import ButtonForm from 'src/components/ButtonForm';
import ButtonWhiteForm from 'src/components/ButtonWhiteForm';
import Checkbox from 'src/components/Checkbox';
import BockInputPay from 'src/components/BockInputPay';
import { ToastContainer, toast } from 'react-toastify';
import api from 'src/services/api';

import {
  Container,
  PopUp,
  BlockLogin,
  LoginMenu,
  InfoPassword,
  CheckRegister,
  Arrow,
  ImageMain,
} from './styles';
// import Checkbox from 'src/styles/themes/light/checkbox';

const RegisterDiscard: FC = () => {
  const url_atual = window.location.href.toLowerCase();

  const history = useHistory();
  const login = () => {
    history.push('/login');
  };
  const home = () => {
    history.push('/home');
  };

  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [recaptchavalue, setRecaptchavalue] = useState<string>('');
  const [show_input, setShowInput] = useState<boolean>(false);
  const [showPassConfirm, setShowPassConfirm] = useState<boolean>(true);
  const [showPass, setShowPass] = useState<boolean>(true);
  const [show_input_confirm, setShowInputConfirm] = useState<boolean>(false);
  const funcPassViewConfirm = (passview: boolean) => {
    setShowPassConfirm(!showPassConfirm);
    setShowInputConfirm(!show_input_confirm);
  };

  useEffect(() => {
    const getLogin = async () => {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));

      const erro = localStorage.getItem('erro');
      const sucesso = localStorage.getItem('sucesso');
    };
    localStorage.removeItem('erro');
    localStorage.removeItem('sucesso');

    getLogin();
  }, []);

  const funcErrorEmail = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const funcErrorPassword = (stateError: boolean) => {
    setErrorPassword(stateError);
  };

  const changeRecaptha = (value) => {
    setRecaptchavalue(value);
  };

  const signInPass = async () => {
    // history.push("/link-enviado");

    if (email == '') {
      toast.error('E-mail não pode ser vazio!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      funcErrorEmail(true);
    } else {
      if (password == '') {
        toast.error('Password não pode ser vazio!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        funcErrorPassword(true);
      } else {
        if (!IsEmail(email)) {
          toast.error('E-mail digitado não é valido!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // if (recaptchavalue == '') {
          //   toast.error('Validação do reCAPCHA é obrigatória!', {
          //     position: 'top-right',
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //   });
          // } else {
          let res: any = {};
          res = await authRequest(email, password);
          // }
        }
      }
    }
  };

  const IsEmail = (email: string) => {
    return true;
  };

  const changeEmail = (email: string) => {
    setEmail(email);
    funcErrorEmail(false);
    funcErrorPassword(false);
  };

  const changePassword = (password: string) => {
    setPassword(password);
    funcErrorPassword(false);
    funcErrorEmail(false);
  };

  const dispatch = useDispatch();

  const loginSSO = () => {
    history.push('/login-sso');
  };

  const forgotMyPassword = () => {
    history.push('/esqueci-minha-senha');
  };

  const authRequest = async (login, pass) => {
    const loading = true;
    dispatch(AuthActions.authLoading(loading));
    const data = {
      email: login,
      password: pass,
    };
    try {
      const response = await api.user.post('/api/v1/login', data);

      const { modules, token } = response.data;
      if (modules.length == 1 && modules[0]?.user_profile == null) {
        const data = {
          uuid: modules[0].id,
        };
        try {
          const response = await api.user.post('/api/v1/login/module', data, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });

          const { companies } = response.data;
          const tokenNew = response.data.token;
          if (companies) {
            if (companies.length == 1) {
              const data = {
                uuid_company: companies[0].id,
              };
              try {
                const response = await api.user.post(
                  '/api/v1/companies/current',
                  data,
                  {
                    headers: {
                      Authorization: 'Bearer ' + tokenNew,
                    },
                  }
                );

                const dataNew = {
                  token: response.data.token,
                };

                window.location.href =
                  'api/v1/logar_by_micro_service/' + response.data.token;
              } catch (error: any) {
                if (
                  error.response.data.error ==
                  'Cannot log in as the account is in use on another device!'
                ) {
                  localStorage.setItem('erro', '2');
                  toast.error(
                    ' Não é possível efetuar o login pois a conta está em uso em outro dispositivo! ',
                    {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                }
              }
            } else {
              dispatch(AuthActions.authSelectCompanie(token, { modules }));
              const loading = false;
              dispatch(AuthActions.authLoading(loading));
              history.push('/escolher-empresa-carrefour');
            }
          }

          // dispatch(AuthActions.authSelectCompanie(token, { companies }));
        } catch (error) {}
      }
    } catch (error: any) {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));
      if (
        error.response.data.message ==
        'The maximum number of login attempts has been reached. We are sending you an email to reset your password.'
      ) {
        toast.error(
          'O número máximo de tentativas de login foi atingido. Estamos lhe enviando um e-mail para redefinir sua senha.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        if (
          error.response.data.message ==
          'Change your password to meet secure password requirements!'
        ) {
          toast.error(
            'Altere sua senha para atender aos requisitos de senha segura!',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          history.push(
            '/login/recriar-senha/' +
              error.response.data.data.id +
              '/' +
              error.response.data.data.token +
              '/senha-forte'
          );
        } else {
          toast.error('Login ou senha inválidos!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      funcErrorPassword(true);
      funcErrorEmail(true);
    }
  };

  const funcPassView = (passview: boolean) => {
    setShowPass(!showPass);
    setShowInput(!show_input);
  };

  return (
    <Container>
      <div>
        <PopUp>
          <Arrow>
            <img src={ArrowDown} alt="Arrow Down" />
          </Arrow>
          <h2>Registrar Descarte</h2>
          <ImageMain>
            <img src={Trash} alt="Arrow Down" />
          </ImageMain>

          <h4>Instruções para registrar seu descarte</h4>

          <p>1. Clique no botão LER QR CODE.</p>
          <p>
            2. Aponte a câmera do celular para o selo que contém o qr code no
            ponto de coleta.
          </p>
          <p>
            3. Se a leitura for bem sucedida você será direcionado para os DADOS
            DA ENTREGA.
          </p>
          <p>4. Informe a quantidade e o tipo de resíduo descartado.</p>
          <p>5. Clique em FINALIZAR.</p>

          <h5>Obs.: Cada entrega pode conter mais de um tipo de resíduo.</h5>

          <ButtonForm type="button">LER QR CODE</ButtonForm>

          <ButtonWhiteForm type="button" onClick={() => home()}>
            CANCELAR
          </ButtonWhiteForm>
        </PopUp>
      </div>
    </Container>
  );
};

export default RegisterDiscard;
