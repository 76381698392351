import { createActions, createReducer } from 'reduxsauce';

/* Types & Actions Creators */
export const { Types, Creators } = createActions({
  authFailure: [],
  authCompany: ['token', 'companySelected', 'modules'],
  authModule: ['token', 'companyModuled'],
  authLogout: [],
  authLoading: ['loading'],
  authLogin: ['token', 'user'],
});

interface AuthState {
  readonly token: string | null;
  readonly isAuthenticated: boolean;
  readonly loading: boolean;
  readonly companySelected: [] | any;
  readonly companyModuled: [] | any;
  readonly user: [] | any;
  readonly modules: [];
}

/* Initial State */
const INITIAL_STATE: AuthState = {
  token: null,
  isAuthenticated: false,
  loading: false,
  modules: [],
  companySelected: [],
  companyModuled: [],
  user: [],
};

/* Reducers */
const authFailure = () => INITIAL_STATE;

const authLoading = (state, { loading }) => ({
  ...state,
  loading,
});

const authCompany = (state, { token, companySelected, modules }) => ({
  ...INITIAL_STATE,
  token,
  companySelected: [companySelected],
  modules,
});

const authModule = (state, { token, companyModuled }) => ({
  ...INITIAL_STATE,
  isAuthenticated: true,
  token,
  companyModuled: [companyModuled],
});

const authLogin = (state, { token, user }) => ({
  ...INITIAL_STATE,
  token,
  user: user,
  isAuthenticated: true,
});

const authLogout = () => INITIAL_STATE;

/* Reducers to types */
const reducer = createReducer(INITIAL_STATE, {
  [Types.AUTH_FAILURE]: authFailure,
  [Types.AUTH_COMPANY]: authCompany,
  [Types.AUTH_MODULE]: authModule,
  [Types.AUTH_LOGOUT]: authLogout,
  [Types.AUTH_LOADING]: authLoading,
  [Types.AUTH_LOGIN]: authLogin,
});

export default reducer;
