import ConfirmChangePassword from './ConfirmChangePassword';
import ConfirmDeparture from './ConfirmDeparture';
import FindPev from './FindPev';
import Login from './Login';
import Main from './Main';
import Register from './Register';
import RegisterDataDiscard from './RegisterDataDiscard';
import RegisterDiscard from './RegisterDiscard';
import RegisterDiscardSucess from './RegisterDiscardSucess';
import RegisterSuccess from './RegisterSuccess';
import Start from './Start';
import ToRecover from './ToRecover';
import ToRecoverSuccess from './ToRecoverSuccess';
import VerificationCode from './VerificationCode';
import MyVouchers from './MyVouchers';

export default {
  Start,
  Login,
  Register,
  ToRecover,
  ToRecoverSuccess,
  RegisterSuccess,
  Main,
  FindPev,
  RegisterDiscard,
  RegisterDataDiscard,
  RegisterDiscardSucess,
  ConfirmDeparture,
  ConfirmChangePassword,
  VerificationCode,
  MyVouchers,
};
