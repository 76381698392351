import { FC, useRef, useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import brandPay from 'src/assets/img/brandPayStart.svg';
import ButtonStart from 'src/components/ButtonStart';

import { Container, Brand, StartInfo } from './styles';

const Start: FC = () => {
  const url_atual = window.location.href.toLowerCase();

  const history = useHistory();
  const login = () => {
    history.push('/login');
  };

  return (
    <Container>
      <div>
        <Brand>
          <img src={brandPay} alt="GreeningPay" />
        </Brand>
        <StartInfo>
          <h4>Bem-vindo(a) a sua plataforma de</h4>
          <h4>
            <span>Logística Reversa</span>
          </h4>
          <p>Entre e contibua para um futuro mais sustentável</p>
        </StartInfo>
        <ButtonStart type="button" onClick={() => login()}>
          ENTRAR
        </ButtonStart>
      </div>
    </Container>
  );
};

export default Start;
