import { FC, useRef, useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ButtonForm from 'src/components/ButtonForm';
import Header from 'src/components/Header';
import api from 'src/services/api';

import {
  Container,
  FindPevTitle,
  FindPevMap,
  FindPevCards,
  PositionButton,
  ContainerCarousel,
  BaseCarrossel,
  Scrolling,
} from './styles';

const Login: FC = () => {
  const url_atual = window.location.href.toLowerCase();

  const history = useHistory();
  const home = () => {
    history.push('/home');
  };

  return (
    <Container>
      <div>
        <Header />
        <FindPevTitle>
          <p>ENCONTRE UM PEV MAIS PRÓXIMO</p>
        </FindPevTitle>
        <FindPevMap>
          {/* Adicionar o Mapa */}
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d3074.154280809076!2d-46.66657843266797!3d-23.61128440630212!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-BR!2sbr!4v1639339339512!5m2!1spt-BR!2sbr"
            style="border:0;"
            allowfullscreen="false"
            loading="lazy"
          ></iframe> */}

          <ContainerCarousel>
            <BaseCarrossel>
              <Scrolling>
                <FindPevCards>
                  <h1>PEV Unidade 1</h1>
                  <h2>Jardim América</h2>
                  <p>Rua João Wagner Wey, 177</p>
                  <p>Jardim América</p>
                  <h3>À 0,012 Km de você</h3>
                </FindPevCards>
                <FindPevCards>
                  <h1>PEV Unidade 1</h1>
                  <h2>Jardim América</h2>
                  <p>Rua João Wagner Wey, 177</p>
                  <p>Jardim América</p>
                  <h3>À 0,012 Km de você</h3>
                </FindPevCards>
                <FindPevCards>
                  <h1>PEV Unidade 1</h1>
                  <h2>Jardim América</h2>
                  <p>Rua João Wagner Wey, 177</p>
                  <p>Jardim América</p>
                  <h3>À 0,012 Km de você</h3>
                </FindPevCards>
                <FindPevCards>
                  <h1>PEV Unidade 1</h1>
                  <h2>Jardim América</h2>
                  <p>Rua João Wagner Wey, 177</p>
                  <p>Jardim América</p>
                  <h3>À 0,012 Km de você</h3>
                </FindPevCards>
              </Scrolling>
            </BaseCarrossel>
          </ContainerCarousel>
        </FindPevMap>

        <PositionButton>
          <ButtonForm type="button" onClick={() => home()}>
            FECHAR
          </ButtonForm>
        </PositionButton>
      </div>
    </Container>
  );
};

export default Login;
