import { FC, useRef, useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Creators as AuthActions } from 'src/store/ducks/auth';
import InputLogin from 'src/components/InputLogin';
import brandPayColor from 'src/assets/img/brandPayColor.svg';
import ButtonForm from 'src/components/ButtonForm';
import BockInputPay from 'src/components/BockInputPay';
import { ToastContainer, toast } from 'react-toastify';
import api from 'src/services/api';
import keys from 'src/services/keys';
import visibility from 'src/assets/img/visibility.png';
import view from 'src/assets/img/view.png';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  Container,
  Brand,
  BlockLogin,
  LoginMenu,
  InfoPolice,
  ReCaptcha,
  ImageUser,
} from './styles';

const Login: FC = () => {
  const url_atual = window.location.href.toLowerCase();

  const history = useHistory();
  const register = () => {
    history.push('/cadastrar');
  };
  const toRecover = () => {
    history.push('/recuperar-senha');
  };

  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [recaptchavalue, setRecaptchavalue] = useState<string>('');
  const [show_input, setShowInput] = useState<boolean>(false);
  const [showPassConfirm, setShowPassConfirm] = useState<boolean>(true);
  const [showPass, setShowPass] = useState<boolean>(true);
  const [show_input_confirm, setShowInputConfirm] = useState<boolean>(false);
  const funcPassViewConfirm = (passview: boolean) => {
    setShowPassConfirm(!showPassConfirm);
    setShowInputConfirm(!show_input_confirm);
  };
  const [recaptcha_privateke, setRecaptcha_privateke] = useState<any>(
    keys.REACT_APP_SITE_KEY
  );
  useEffect(() => {
    const getLogin = async () => {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));
      const erro = localStorage.getItem('erro');
      const sucesso = localStorage.getItem('sucesso');
    };
    localStorage.removeItem('erro');
    localStorage.removeItem('sucesso');

    getLogin();
  }, []);

  const funcErrorEmail = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const funcErrorPassword = (stateError: boolean) => {
    setErrorPassword(stateError);
  };

  const changeRecaptha = (value) => {
    setRecaptchavalue(value);
  };

  const signInPass = async () => {
    // history.push("/link-enviado");

    if (email == '') {
      toast.error('E-mail não pode ser vazio!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      funcErrorEmail(true);
    } else {
      if (password == '') {
        toast.error('Password não pode ser vazio!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        funcErrorPassword(true);
      } else {
        if (!IsEmail(email)) {
          toast.error('E-mail digitado não é valido!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          if (recaptchavalue == '') {
            toast.error('Validação do reCAPCHA é obrigatória!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            let res: any = {};
            res = await authRequest(email, password);
          }
        }
      }
    }
  };

  const IsEmail = (email: string) => {
    return true;
  };

  const changeEmail = (email: string) => {
    setEmail(email);
    funcErrorEmail(false);
    funcErrorPassword(false);
  };

  const changePassword = (password: string) => {
    setPassword(password);
    funcErrorPassword(false);
    funcErrorEmail(false);
  };

  const dispatch = useDispatch();

  const loginSSO = () => {
    history.push('/login-sso');
  };

  const forgotMyPassword = () => {
    history.push('/esqueci-minha-senha');
  };

  const authRequest = async (login, pass) => {
    const loading = true;
    dispatch(AuthActions.authLoading(loading));
    const data = {
      email: login,
      password: pass,
    };
    try {
      const response = await api.user.post('/login', data);
      console.log(response);
      const { token, nome, email, success } = response.data;
      const dataUser = {
        nome: nome,
        email: email,
      };
      if (success) {
        dispatch(AuthActions.authLogin(token, dataUser));
      }
    } catch (error: any) {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));

      toast.error('Login ou senha inválidos!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      funcErrorPassword(true);
      funcErrorEmail(true);
    }
  };

  const funcPassView = (passview: boolean) => {
    setShowPass(!showPass);
    setShowInput(!show_input);
  };

  return (
    <Container>
      <div>
        <Brand>
          <img src={brandPayColor} alt="GreeningPay" />
        </Brand>
        <LoginMenu>
          <a href={url_atual}>
            <span>ACESSE</span>
          </a>
          <a onClick={() => register()}>CADASTRE-SE</a>
        </LoginMenu>
        <BlockLogin>
          <BockInputPay error={errorEmail}>
            <InputLogin
              type="email"
              placeholder="E-mail"
              onChange={(e) => changeEmail(e.target.value)}
            />
          </BockInputPay>

          <BockInputPay error={errorEmail}>
            <InputLogin
              type={show_input ? 'text' : 'password'}
              placeholder="Senha de acesso"
              onChange={(e) => changePassword(e.target.value)}
            />
            <ImageUser onClick={() => funcPassView(showPass)}>
              <img
                src={showPass ? visibility : view}
                style={{
                  position: 'absolute',
                  width: '8%',
                  float: 'left',
                  right: '8px',
                  marginTop: '22px',
                }}
                alt="greenPay"
              />
            </ImageUser>
          </BockInputPay>

          <ReCaptcha>
            <ReCAPTCHA
              sitekey={recaptcha_privateke}
              size="normal"
              badge="inline"
              onChange={(e) => changeRecaptha(e)}
              width={100}
            />
          </ReCaptcha>

          <ButtonForm type="button" onClick={() => signInPass()}>
            CONTINUAR
          </ButtonForm>

          <p>
            <a onClick={() => toRecover()}>Esqueci minha senha</a>
          </p>
        </BlockLogin>
        <InfoPolice>
          <p>Ao continuar você está de acordo com nossa </p>
          <p>
            <span>política de privacidade e uso de dados.</span>
          </p>
        </InfoPolice>
      </div>
    </Container>
  );
};

export default Login;
