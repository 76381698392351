import { FC, useRef, useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import brandPay from 'src/assets/img/brandPayColor.svg';
import ButtonStart from 'src/components/ButtonStart';

import { Container, Brand, StartInfo, BoxButtonContinue } from './styles';

const Start: FC = () => {
  const url_atual = window.location.href.toLowerCase();

  const history = useHistory();
  const login = () => {
    history.push('/login');
  };

  return (
    <Container>
      <div>
        <Brand>
          <img src={brandPay} alt="GreeningPay" />
        </Brand>
        <StartInfo>
          <h4>PARABÉNS, SEU CADASTRO FOI CONCLUÍDO COM SUCESSO!</h4>
          <p>
            Enviamos uma mensagem no seu e-mail cadastrado contendo um link para
            ativação da sua conta Greening Pay.
          </p>
          <p>Após ativar a conta, retorne aqui e toque em continuar.</p>
        </StartInfo>
        <BoxButtonContinue>
          <ButtonStart type="button" onClick={() => login()}>
            CONTINUAR
          </ButtonStart>
        </BoxButtonContinue>
      </div>
    </Container>
  );
};

export default Start;
