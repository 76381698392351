import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  height: auto;
  > div {
    min-height: 100vh;
    background-color: #f0f0f0;
    padding-bottom: 50px;
  }
`;

export const InputLabel = styled.label`
  color: #00adee !important;
  text-align: left !important;
  font-family: roboto;
  font-weight: 900 !important;
  font-size: 14px;
  line-height: 30px;
  width: 100% !important;
  display: block !important;
  margin-bottom: -15px !important;
  vertical-align: middle !important;
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 80%;
    max-width: 350px;
    height: auto;
    margin: 0 auto;
    margin-top: 80px !important;
  }
`;
export const InfoToRecover = styled.div`
  display: block;
  align-items: center;
  padding-left: 10px;
  width: 80%;
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  font-family: roboto;
  font-weight: 900 !important;
  cursor: pointer;
  color: #000000;
  h4 {
    font-size: 16px !important;
    margin-bottom: 20px !important;
  }
  p {
    color #808080 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    letter-spacing: 1px;
    font-weight: normal !important;
  }
`;

export const BlockLogin = styled.div`
    width: 80%;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px 25px 30px 25px;
    margin-top: 30px;
    > h1 {
      color: #00ADEE;
      font-family: roboto;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
    }
    > img {
      width: 260px !important;
      margin-top: 30px;
    }
    > p {
      color: #808080;
      font-family: roboto;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin-top: 35px;
    }
    > h2 {
      color: #000000;
      font-family: roboto;
      font-weight: bold;
      font-size: 15px;
      line-height: 22px;
      margin-top:50px;
      margin-bottom: 39px;
    }
    > h3 {
      color: #00ADEE;
      font-family: roboto;
      font-weight: bold;
      font-size: 24px;
      line-height: 22px;
      letter-spacing: 4px;
      // margin-bottom: 39px;
    }
  }
`;
export const BlockData = styled.div`
    margin-bottom: 30px;
  }
`;

export const SectionData = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  color: #202020;
  font-family: roboto;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  height: 30px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 5px;
`;
export const InfoName = styled.div`
  width: 70%;
`;
export const InfoQtd = styled.div`
  width: 20%;
  text-align: right;
  padding-right: 10px;
`;
export const InfoRemove = styled.div`
  width: 10%;
  text-align: right;
  color: #00a96d;
  font-weight: bold !important;
`;
