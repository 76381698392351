import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  height: auto;
  > div {
    min-height: 100vh;
    background-color: #f0f0f0;
    padding-bottom: 50px;
  }
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 80%;
    max-width: 350px;
    height: auto;
    margin: 0 auto;
    margin-top: 80px !important;
  }
`;
export const InfoToRecover = styled.div`
  display: block;
  align-items: center;
  padding-left: 10px;
  width: 80%;
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  font-family: roboto;
  font-weight: 900 !important;
  cursor: pointer;
  color: #000000;
  h4 {
    font-size: 16px !important;
    margin-bottom: 20px !important;
  }
  p {
    color #808080 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    letter-spacing: 1px;
    font-weight: normal !important;
  }
`;

export const BlockLogin = styled.div`
    width: 80%;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px 25px 30px 25px;
    margin-top: 50px;
  }
`;
export const InfoErrorToRecover = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
    font-family: roboto;
    font-weight: normal;
    color: #808080;
    margin-bottom: 25px;
    p {
      text-align: center !important;
      color: #A0A0A0 !important;
      padding-top: 0px !important;
      margin-bottom: 0px !important;  
      line-height: 20px !important;
      span {
        color: #ff0000 !important;
        padding-bottom: 0 !important;
        margin-bottom: 0px !important;
        font-weight: bold !important;
      }
    }
  }
`;
