import { FC, useRef, useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Creators as AuthActions } from 'src/store/ducks/auth';
import InputLogin from 'src/components/InputLogin';
import brandPayColor from 'src/assets/img/brandPayColor.svg';
import ButtonForm from 'src/components/ButtonForm';
import ButtonWhiteForm from 'src/components/ButtonWhiteForm';
import Checkbox from 'src/components/Checkbox';
import BockInputPay from 'src/components/BockInputPay';
import { ToastContainer, toast } from 'react-toastify';
import api from 'src/services/api';
import { cpfMask, cpfValida, mphone } from './mask';
import {
  Container,
  Brand,
  BlockLogin,
  InfoToRecover,
  InfoErrorToRecover,
} from './styles';

const ToRecover: FC = () => {
  const url_atual = window.location.href.toLowerCase();

  const history = useHistory();
  const login = () => {
    history.push('/login');
  };

  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [recaptchavalue, setRecaptchavalue] = useState<string>('');
  const [show_input, setShowInput] = useState<boolean>(false);
  const [showPassConfirm, setShowPassConfirm] = useState<boolean>(true);
  const [showPass, setShowPass] = useState<boolean>(true);
  const [show_input_confirm, setShowInputConfirm] = useState<boolean>(false);
  const funcPassViewConfirm = (passview: boolean) => {
    setShowPassConfirm(!showPassConfirm);
    setShowInputConfirm(!show_input_confirm);
  };

  useEffect(() => {
    const getLogin = async () => {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));

      const erro = localStorage.getItem('erro');
      const sucesso = localStorage.getItem('sucesso');
    };
    localStorage.removeItem('erro');
    localStorage.removeItem('sucesso');

    getLogin();
  }, []);

  const funcErrorEmail = (stateError: boolean) => {
    setErrorEmail(stateError);
  };

  const funcErrorPassword = (stateError: boolean) => {
    setErrorPassword(stateError);
  };

  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [nome, setNome] = useState<string>('');
  const [celular, setCelular] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');

  const [oito_caracteres, setOitoCaracteres] = useState<boolean>(false);
  const [um_numero, setUmNumero] = useState<boolean>(false);
  const [um_caractere_especial, setUmCaractereEspecial] =
    useState<boolean>(false);
  const [uma_letra, setUmaLetra] = useState<boolean>(false);
  const [uma_maiuscula, setUmaMaiuscula] = useState<boolean>(false);
  const [uma_minuscula, setUmaMinuscula] = useState<boolean>(false);
  const [nova_senha_confirm, setNovaSenhaConfirm] = useState<boolean>(false);
  const [acentuacao, setAcentuacao] = useState<boolean>(true);
  const [validado, setValidado] = useState<boolean>(false);

  const changePassword = (password: string) => {
    const numeros = /([0-9])/;
    const alfabeto = /([a-zA-Z])/;
    const maiuscula = /[A-Z]/;
    const minuscula = /([a-z])/;
    const chEspeciais = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
    const acentuacao = /([áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ])/;

    if (password.length < 8) {
      setOitoCaracteres(false);
    } else {
      setOitoCaracteres(true);
    }

    if (!password.match(acentuacao)) {
      setAcentuacao(false);
    } else {
      setAcentuacao(true);
    }

    if (!password.match(maiuscula)) {
      setUmaMaiuscula(false);
    } else {
      setUmaMaiuscula(true);
    }

    if (!password.match(minuscula)) {
      setUmaMinuscula(false);
    } else {
      setUmaMinuscula(true);
    }

    if (!password.match(chEspeciais)) {
      setUmCaractereEspecial(false);
    } else {
      setUmCaractereEspecial(true);
    }

    if (!password.match(numeros)) {
      setUmNumero(false);
    } else {
      setUmNumero(true);
    }

    if (!password.match(alfabeto)) {
      setUmaLetra(false);
    } else {
      setUmaLetra(true);
    }

    if (passwordConfirm != password) {
      setNovaSenhaConfirm(false);
    } else {
      if (passwordConfirm == '' && password == '') {
        setNovaSenhaConfirm(false);
      } else {
        setNovaSenhaConfirm(true);
      }
    }

    setPassword(password);
    funcErrorPassword(false);
  };

  const changePasswordConfirm = (passwordConfirm: string) => {
    if (passwordConfirm != password) {
      setNovaSenhaConfirm(false);
    } else {
      if (passwordConfirm == '' && password == '') {
        setNovaSenhaConfirm(false);
      } else {
        setNovaSenhaConfirm(true);
      }
    }

    setPasswordConfirm(password);
    funcErrorPasswordConfirm(false);
  };

  const changeNome = (nome: string) => {
    setNome(nome);
  };

  const changeCpf = (cpf: string) => {
    console.log(cpfMask(cpf));
    setCpf(cpfMask(cpf));
  };

  const changeCelular = (celular: string) => {
    setCelular(celular);
  };

  const funcErrorPasswordConfirm = (stateError: boolean) => {
    setErrorPassword(stateError);
  };

  const backLogin = () => {
    history.push('/');
  };

  const IsEmail = (email: string) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const forgotRequest = async () => {
    if (acentuacao) {
      await setErrorPassword(true);
      toast.error(' Senha não pode possuir acento! ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (!oito_caracteres) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter no mínimo 8 caracteres ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (!um_numero) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 número ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!um_caractere_especial) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 caractere especial ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!uma_letra) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 letra ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!uma_maiuscula) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Ter pelo menos 1 letra maiúscula ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (!nova_senha_confirm) {
      await setErrorPassword(true);
      toast.error(' Senha Inválida - Senhas não podem ser diferentes ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    redefinirPass();
    // history.push('/');
  };

  const registerSucesso = () => {
    history.push('/cadastro-realizado');
  };

  const redefinirPass = async () => {
    if (!oito_caracteres) {
      funcErrorPassword(true);
    } else {
      if (!um_numero) {
        funcErrorPassword(true);
      } else {
        if (!um_caractere_especial) {
          funcErrorPassword(true);
        } else {
          if (!uma_letra) {
            funcErrorPassword(true);
          } else {
            if (!uma_maiuscula) {
              funcErrorPassword(true);
            } else {
              if (!nova_senha_confirm) {
                funcErrorPassword(true);
              } else {
                if (acentuacao) {
                  funcErrorPassword(true);
                } else {
                  const data = {
                    email: email,
                    cpf: cpf,
                    password: password,
                  };
                  try {
                    const loading = true;
                    dispatch(AuthActions.authLoading(loading));
                    const response = await api.user.post(
                      '/password-update',
                      data
                    );
                    funcErrorPassword(false);

                    if (response.data.success == false) {
                      toast.error(response.data.error, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      const loading = false;
                      dispatch(AuthActions.authLoading(loading));
                      funcErrorPassword(false);
                    } else {
                      toast.success('Alteração realizada com sucesso', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      login();
                      const loading = false;
                      dispatch(AuthActions.authLoading(loading));
                      funcErrorPassword(false);
                    }
                  } catch (error) {
                    const loading = false;
                    dispatch(AuthActions.authLoading(loading));
                    funcErrorPassword(false);
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const changeEmail = (email: string) => {
    setEmail(email);
    funcErrorEmail(false);
    funcErrorPassword(false);
  };

  const authRequest = async () => {
    const loading = true;
    dispatch(AuthActions.authLoading(loading));
    const data = {
      email: email,
      cpf: cpf,
    };
    try {
      const response = await api.user.post('/recuperar-senha', data);
      console.log(response);
      const { success } = response.data;

      if (success) {
        setValidado(true);
        const loading = false;
        dispatch(AuthActions.authLoading(loading));
      }
    } catch (error: any) {
      const loading = false;
      dispatch(AuthActions.authLoading(loading));

      toast.error('Cpf ou email inválidos!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      funcErrorPassword(true);
      funcErrorEmail(true);
    }
  };

  return (
    <Container>
      <div>
        <Brand>
          <img src={brandPayColor} alt="GreeningPay" />
        </Brand>
        <InfoToRecover>
          <h4>RECUPERAR SENHA</h4>
          <p>Problemas para acessar sua conta?</p>
          <p>Informe o e-mail cadastrado e CPF para verificarmos seu acesso.</p>
        </InfoToRecover>
        <BlockLogin>
          {!validado && (
            <>
              <BockInputPay error={errorEmail}>
                <InputLogin
                  type="email"
                  placeholder="E-mail"
                  onChange={(e) => changeEmail(e.target.value)}
                />
              </BockInputPay>
              <BockInputPay error={errorEmail}>
                <InputLogin
                  type="text"
                  placeholder="CPF"
                  value={cpf}
                  onChange={(e) => changeCpf(e.target.value)}
                />
              </BockInputPay>

              <ButtonForm type="button" onClick={() => authRequest()}>
                CONTINUAR
              </ButtonForm>
            </>
          )}
          {/* <InfoErrorToRecover>
            <p>
              <span>E-mail ou CPF não encontrado.</span>
            </p>
            <p>
              Verifique se os campos foram preenchidos de forma correta e tente
              novamente.
            </p>
          </InfoErrorToRecover> */}

          {validado && (
            <>
              <BockInputPay error={errorEmail}>
                <InputLogin
                  type="password"
                  placeholder="Senha"
                  onChange={(e) => changePassword(e.target.value)}
                />
              </BockInputPay>

              <BockInputPay error={errorEmail}>
                <InputLogin
                  type="password"
                  placeholder="Confirme a senha"
                  onChange={(e) => changePasswordConfirm(e.target.value)}
                />
              </BockInputPay>

              <ButtonForm type="button" onClick={() => forgotRequest()}>
                ALTERAR PASSWORD
              </ButtonForm>
            </>
          )}

          <ButtonWhiteForm type="button" onClick={() => login()}>
            CANCELAR
          </ButtonWhiteForm>
        </BlockLogin>
      </div>
    </Container>
  );
};

export default ToRecover;
