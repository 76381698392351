import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
  height: auto;
  > div {
    min-height: 100vh;
    background-color: #f0f0f0;
    padding-bottom: 50px;
  }
`;
export const ReCaptcha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
`;

export const ImageUser = styled.div`
  display: flex;

  > img {
    width: 55%;
    height: auto;
  }
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 80%;
    max-width: 350px;
    height: auto;
    margin: 0 auto;
    margin-top: 80px !important;
  }
`;
export const LoginMenu = styled.div`
  display: flex;
  align-items: center;
  // background-color: #ff0000;
  width: 80%;
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
  margin-top: 80px;
  font-size: 18px;
  font-family: roboto;
  font-weight: 900 !important;
  cursor: pointer;
  a {
    color: #000000;
    text-decoration: none;
    text-align: center;
    padding: 0 20px;
  }
  a span {
    border-bottom: 4px solid #00adee;
    padding-bottom: 10px !important;
    padding: 0 20px;
  }
`;

export const BlockLogin = styled.div`
    width: 80%;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px 25px 30px 25px;
    margin-top: 50px;
    p {
      margin-top: 34px !important;
      font-size: 16px;
      font-family: roboto;
      font-weight: normal;
    }
    a {
      color: #B0B0B0;    
      text-decoration: none;  
    }
  }
`;
export const InfoPolice = styled.div`
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    p {
      font-size: 14px;
      font-family: roboto;
      font-weight: normal;
      color: #808080;
      line-height: 22px;
    }
    p span {
      color: #00ADEE;    
      text-decoration: none;  
    }
  }
`;
