import { FC, useRef, useState, useEffect } from 'react';
import Header from 'src/components/Header';
import Menu from 'src/components/Menu';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import userProfile from 'src/assets/img/icon-profile-user.png';
import highlights1 from 'src/assets/img/highlights-1.png';
import highlights2 from 'src/assets/img/highlights-2.png';
import Icone1 from 'src/assets/img/ico-btn-1.svg';
import Icone2 from 'src/assets/img/ico-btn-2.svg';
import Icone3 from 'src/assets/img/ico-btn-3.svg';
import { RootState } from 'src/store/ducks/index';
import { Creators as AuthActions } from 'src/store/ducks/auth';
import InputLogin from 'src/components/InputLogin';
import ButtonForm from 'src/components/ButtonForm';
import BockInputPay from 'src/components/BockInputPay';

import { ToastContainer, toast } from 'react-toastify';
import api from 'src/services/api';

import {
  Container,
  MainInfoUser,
  ImgInfoProfile,
  DataUserProfile,
  DataNumbers,
  InfoUpdate,
  HighlightsTitle,
  HighlightsImages,
  FunctionalityTitle,
  FunctionalityButons,
  SingleButton,
} from './styles';

const Login: FC = () => {
  const url_atual = window.location.href.toLowerCase();

  const history = useHistory();

  const findPev = () => {
    history.push('/encontre-um-pev');
  };
  const registerDiscard = () => {
    history.push('/registrar-descarte');
  };
  const myVouchers = () => {
    history.push('/meus-vouchers');
  };

  const { user, token } = useSelector((state: RootState) => state.auth);
  return (
    <Container>
      <div>
        <Menu />
        <Header />

        <MainInfoUser>
          <ImgInfoProfile>
            <img src={userProfile} />
          </ImgInfoProfile>
          <DataUserProfile>
            <h4>Olá, {user.nome}</h4>
            <DataNumbers>
              <p>Qtde. de vouchers:</p>
              <span>5</span>
            </DataNumbers>
            <InfoUpdate>
              <p>Atualizado em:</p>
              <span>01/04/2022 - 12:34</span>
            </InfoUpdate>
          </DataUserProfile>
        </MainInfoUser>

        <HighlightsTitle>
          <p>DESTAQUES</p>
        </HighlightsTitle>
        <HighlightsImages>
          <img src={highlights1} />
          {/* <img src={highlights2} /> */}
        </HighlightsImages>

        <FunctionalityTitle>
          <p>FUNCIONALIDADES</p>
        </FunctionalityTitle>
        <FunctionalityButons>
          <SingleButton onClick={() => registerDiscard()}>
            <img src={Icone3} />
            <p>REGISTRAR DESCARTE</p>
          </SingleButton>
          <SingleButton onClick={() => findPev()}>
            <img src={Icone1} />
            <p>ENCONTRAR PEV</p>
          </SingleButton>
          <SingleButton onClick={() => myVouchers()}>
            <img src={Icone2} />
            <p>MEUS VOUCHERS</p>
          </SingleButton>
        </FunctionalityButons>
      </div>
    </Container>
  );
};

export default Login;
