import { all, takeLatest } from 'redux-saga/effects';
import { Types as AuthTypes } from 'src/store/ducks/auth';

import { authSetToken, authLogout, authLogin } from './auth';

function* rootSaga(): Generator {
  return yield all([
    takeLatest('persist/REHYDRATE', authSetToken),
    takeLatest(AuthTypes.AUTH_LOGOUT, authLogout),
    takeLatest(AuthTypes.AUTH_LOGIN, authLogin),
  ]);
}

export default rootSaga;
