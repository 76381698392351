import React from 'react';

import { Container } from './styles';

interface BockInputPayProps {
  error: boolean;
}

const BockInputPay: React.FC<BockInputPayProps> = ({ children, error }) => (
  <Container error={error}>{children}</Container>
);

export default BockInputPay;
