import { FC } from 'react';

import brandPayColor from 'src/assets/img/brandPayColor.svg';
import close from 'src/assets/img/icon-close.svg';
import userProfile from 'src/assets/img/icon-profile-user.png';

import {
  MyMenu,
  FooterMenu,
  HeaderMenu,
  SectionClose,
  SectionInfoUser,
} from './styles';

const Menu: FC = ({ children }) => {
  return (
    <MyMenu>
      <HeaderMenu>
        <SectionClose>
          <img src={close} alt="fechar" />
          <p>MENU</p>
        </SectionClose>
        <SectionInfoUser>
          <div>
            <img src={userProfile} />
            <p>Yuri Eduardo Pereira …</p>
            <p>
              <span>bauer8661@gmail.com</span>
            </p>
          </div>
        </SectionInfoUser>
      </HeaderMenu>
      <div>
        <ul>
          <li>
            <a>TROCAR SENHA</a>
          </li>
          <li>
            <a>SUPORTE</a>
          </li>
          <li>
            <a>SAIR DO APP</a>
          </li>
        </ul>
      </div>
      <FooterMenu>
        <img src={brandPayColor} alt="GreeningPay" />

        <p>©2022 GreenPlat – v1.2.3</p>
      </FooterMenu>
    </MyMenu>
  );
};

export default Menu;
