import { FC, useState } from 'react';

import menu from 'src/assets/img/icon_menu.svg';
import notification from 'src/assets/img/icon-notifications.svg';
import brandPayColor from 'src/assets/img/brandPayColor.svg';

import {
  MyHeader,
  ContentHeader,
  MenuHeader,
  BrandHeader,
  AlertHeader,
} from './styles';

const Header: FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  return (
    <MyHeader>
      <ContentHeader>
        <MenuHeader>
          <a>
            <img src={menu} />
          </a>
        </MenuHeader>

        <BrandHeader>
          <a href="#">
            <img src={brandPayColor} alt="GreeningPay" />
          </a>
        </BrandHeader>

        <AlertHeader>
          <a href="#">
            <img src={notification} />
          </a>
        </AlertHeader>
      </ContentHeader>
    </MyHeader>
  );
};

export default Header;
